import startsWith from 'lodash/fp/startsWith'
import includes from 'lodash/fp/includes'
import negate from 'lodash/fp/negate'

import config from '@tofu/apps/shop/constants/env'

/**
 * Checks for absolute allplants.com links
 * the // startes the condition after either https// or http//
 */
const isInternalURL = includes(`${config.shop_base_url}`)
const isExternalURL = negate(isInternalURL)

/**
 * Check for links that start with a slash
 */
const isRelativeURL = startsWith('/')

export { isRelativeURL, isInternalURL, isExternalURL }
