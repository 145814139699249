import { useEffect, useState } from 'react'
import debounce from 'lodash/debounce'
import { breakpoints } from '@tofu/apps/shop/styles/variables'

const { tablet, desktop } = breakpoints

const useViewPort = () => {
  const [values, setViewPort] = useState({ width: 0, height: 0 })

  const { width } = values || {}

  const isMobileView = width <= tablet
  const isTabletView = width > tablet && width < desktop
  const isDesktopView = width >= desktop

  const handleResize = () => {
    setViewPort({
      width: window.innerWidth,
      height: window.innerHeight
    })
  }

  // add event listener to window when component mounts
  useEffect(() => {
    const debouncedResize = debounce(handleResize, 300)

    setViewPort({
      width: window.innerWidth,
      height: window.innerHeight
    })
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', debouncedResize)
    }
    return () => window.removeEventListener('resize', debouncedResize)
  }, [])

  return {
    ...values,
    isMobileView,
    isTabletView,
    isDesktopView
  }
}

export default useViewPort
