import NextLink from 'next/link'
import PropTypes from 'prop-types'
import { forwardRef } from '@chakra-ui/react'

import { trackClick } from '@tofu/shared/utils/analytics'
import { isRelativeURL, isInternalURL } from '@tofu/apps/shop/utils/url'

const renderAnchorLink = ({
  isExternal, // eslint-disable-line @typescript-eslint/no-unused-vars
  shallow, // eslint-disable-line @typescript-eslint/no-unused-vars
  handleOnClick,
  children,
  href,
  ...rest
}) => (
  <a href={href} onClick={handleOnClick} {...rest}>
    {children}
  </a>
)

const Link = forwardRef((props, _ref) => {
  const { href, isExternal, shallow, as, name, label } = props

  /* eslint-disable consistent-return */
  const handleOnClick = () => {
    if (!name || !label) return null
    trackClick(name, { label })
  }

  if (isExternal) {
    return renderAnchorLink({
      handleOnClick,
      ...props
    })
  }

  if (isRelativeURL(href) || isInternalURL(href)) {
    return (
      <NextLink as={as} href={href} passHref shallow={shallow}>
        {renderAnchorLink({
          handleOnClick,
          'data-testid': 'next-link',
          ...props
        })}
      </NextLink>
    )
  }

  return renderAnchorLink({ handleOnClick, ...props })
})

Link.defaultProps = {
  as: null,
  isExternal: false,
  label: null,
  name: null,
  shallow: false
}

/* eslint-disable react/no-unused-prop-types */
Link.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  isExternal: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  shallow: PropTypes.bool
}

export default Link
