/* eslint-disable react/no-danger */
import { RichText } from 'prismic-reactjs'
import PropTypes from 'prop-types'
import Image from 'next/image'
import MuxPlayer from '@mux/mux-player-react'
import styled from 'styled-components'

import { AspectRatio } from '@chakra-ui/react'
import { Box } from '@tofu/shared/ui/atoms/box'
import { Flex } from '@tofu/shared/ui/atoms/flex'
import { Text } from '@tofu/shared/ui/atoms/text'
import { Button } from '@tofu/shared/ui/atoms/button'

import Link from '@tofu/apps/shop/components/common/link'

import useViewPort from '@tofu/apps/shop/hooks/viewport'
import { linkResolver } from '@tofu/apps/shop/services/prismic'
import { VisuallyHidden } from '@tofu/apps/shop/styles/accessibleComponents'

import { breakpoints } from '@tofu/apps/shop/styles/variables'

const VideoWrapper = styled.div`
  mux-player {
    --seek-backward-button: none;
    --seek-forward-button: none;

    /* Target all sections by excluding the section prefix */
    
    
    // --play-button: none;
    --live-button: none;
    --seek-backward-button: none;
    --seek-forward-button: none;
    // --mute-button: none;
    --captions-button: none;
    // --airplay-button: none;
    // --pip-button: none;
    --fullscreen-button: none;
    // --cast-button: none;
    --playback-rate-button: none;
    --volume-range: none;
    --time-range: none;
    --time-display: none;
    --duration-display: none;
    --rendition-selectmenu: none;  
    
    

    ::part(center), [part~='center'] {
    width: 90px;
    height: 90px;
    transition: background 0.4s;
    padding: 24px;
    --media-control-background: #000;
    --media-control-hover-background: var(--_accent-color);
  }
`

// load straight from prismic CDN
const imageLoader = ({ src }) => src

const renderImage = (image) => (
  <Image
    alt={image.alt}
    height={image.dimensions.height}
    layout='intrinsic'
    src={image.url}
    loader={imageLoader}
    width={image.dimensions.width}
  />
)

const TwoColumn = ({
  cta,
  cta_style,
  cta_url,
  image_position,
  mobile_text_position,
  title,
  title_line_2,
  mux_video_id,
  description,
  image,
  image_mobile,
  variant,
  video_src
}) => {
  const href = linkResolver(cta_url)
  const { isMobileView } = useViewPort()

  const hasDesktopImage = !!image?.url
  const hasMobileImage = !!image_mobile?.url

  const imgSrc = hasMobileImage && isMobileView ? image_mobile : image

  const direction = image_position === 'left' ? 'row' : 'row-reverse'
  let maxWidth = `${breakpoints.largeDesktop}px`
  let marginX = [0, 0, 'auto']
  let imageColWidth = ['full', 'full', '50%']
  let textColWidth = ['full', 'full', '50%']
  let textWidth = ['100%', '100%', '90%']
  switch (variant) {
    case 'flush':
      maxWidth = '100%'
      marginX = 0
      textWidth = ['100%', '100%', '70%']
      break
    case 'review':
      maxWidth = `${breakpoints.desktop}px`
      imageColWidth = ['full', 'full', '33.3%']
      textColWidth = ['full', 'full', '66.6%']
      break
    default:
  }

  return (
    <Flex
      alignContent='center'
      flexDirection={direction}
      flexWrap='wrap'
      justifyContent='center'
      maxWidth={maxWidth}
      mx={marginX}
      my={[8, 8, 16]}
      px={[8, 8, 0]}
    >
      <Box
        mb={[8, 8, 0]}
        order={mobile_text_position === 'top' && [1, 1, 0]}
        width={imageColWidth}
      >
        {video_src?.html ? (
          <>
            <style global>{`
              /* CSS override to allow responsive videos */
              .videoWrapper iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              }
            `}</style>
            <div
              className='videoWrapper'
              dangerouslySetInnerHTML={{ __html: video_src?.html }}
              style={{
                position: 'relative',
                paddingBottom: '56.25%' /* 16:9 */,
                height: 0
              }}
            />
          </>
        ) : (
          <>
            {!mux_video_id && hasDesktopImage ? renderImage(imgSrc) : null}

            {mux_video_id && hasDesktopImage && (
              <VideoWrapper>
                <AspectRatio ratio={1}>
                  <MuxPlayer
                    poster={image?.url}
                    playbackId={mux_video_id}
                    accent-color='var(--chakra-colors-allplantsYellow)'
                  />
                </AspectRatio>
              </VideoWrapper>
            )}
          </>
        )}
      </Box>
      <Flex
        alignItems='flex-start'
        flexDirection='column'
        justifyContent='center'
        mb={mobile_text_position === 'top' && 4}
        order={mobile_text_position === 'top' && [0, 0, 1]}
        pl={[0, 0, 16]}
        width={textColWidth}
      >
        <Text as='h2' textStyle='h3' mb={3}>
          {RichText.asText(title)}
          {title_line_2?.length === 1 && title_line_2[0].text !== '' && (
            <Box as='span' display='block'>
              {RichText.asText(title_line_2)}
            </Box>
          )}
        </Text>
        <Text
          as='div'
          textStyle='body'
          mb={3}
          sx={{ p: { marginBottom: 3 } }}
          width={textWidth}
        >
          {RichText.render(description)}
        </Text>
        {cta &&
          href &&
          (cta_style === 'Button' ? (
            <Button
              as={Link}
              href={href}
              label={cta}
              name='cta'
              target={cta_url.target}
              variant='solid'
              colorScheme='blackAlpha'
            >
              {cta}
              {cta_url.target && (
                <VisuallyHidden>Opens in a new window</VisuallyHidden>
              )}
            </Button>
          ) : (
            <Button
              as={Link}
              pl={0}
              href={href}
              label={cta}
              name='cta'
              variant='text'
              target={cta_url.target}
            >
              {cta}
              {cta_url.target && (
                <VisuallyHidden>Opens in a new window</VisuallyHidden>
              )}
            </Button>
          ))}
      </Flex>
    </Flex>
  )
}

TwoColumn.defaultProps = {
  cta: null,
  cta_style: null,
  cta_url: null,
  description: null,
  image: null,
  image_mobile: null,
  image_position: null,
  mobile_text_position: 'bottom',
  title: null,
  title_line_2: null,
  variant: null,
  video_src: null
}

TwoColumn.propTypes = {
  cta: PropTypes.string,
  cta_style: PropTypes.oneOf(['Button', 'Link', null]),
  cta_url: PropTypes.shape({
    target: PropTypes.string,
    url: PropTypes.string
  }),
  description: PropTypes.arrayOf(PropTypes.object),
  image: PropTypes.shape({
    alt: PropTypes.string,
    dimensions: PropTypes.shape({
      height: PropTypes.number,
      width: PropTypes.number
    }),
    url: PropTypes.string
  }),
  image_mobile: PropTypes.shape({
    alt: PropTypes.string,
    dimensions: PropTypes.shape({
      height: PropTypes.number,
      width: PropTypes.number
    }),
    url: PropTypes.string
  }),
  image_position: PropTypes.string,
  mobile_text_position: PropTypes.oneOf(['bottom', 'top']),
  title: PropTypes.arrayOf(PropTypes.object),
  title_line_2: PropTypes.arrayOf(PropTypes.object),
  variant: PropTypes.string,
  video_src: PropTypes.shape({
    html: PropTypes.string
  })
}

export default TwoColumn
